import React from "react";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import { IoIosSearch } from "react-icons/io";
import SubscribeEmail from "../../../components/blog/blog-sidebar/SubscribeEmail";

import styles from "./BlogSidebar.module.scss";

const BlogSidebar = () => {
  return (
    <div className="voopoSidebar">
      {/* Start Serarch */}
      <div className={`${styles.vpWidget} ${styles.search} mb--60`}>
        <input type="text" placeholder="Chercher Ici" />
        <button>
          <IoIosSearch />
        </button>
      </div>
      {/* End Serarch */}
      {/* Start Newsleter */}
      <div className={`${styles.vpWidget} ${styles.newsletter} mb--60`}>
        <h4>S'abonner à la Newsletter</h4>
        <SubscribeEmail mailchimpUrl="//" />
      </div>
      {/* End Newsleter */}
      {/* Start Recent Post */}
      <div className={`${styles.vpWidget} ${styles.recentPostWrap} mb--60`}>
        <Tab.Container defaultActiveKey="recent">
          <Nav
            variant="pills"
            className={`${styles.voopoNav} nav justify-content-center`}
          >
            <Nav.Item className={styles.navItem}>
              <Nav.Link eventKey="recent">Récent</Nav.Link>
            </Nav.Item>
            <Nav.Item className={styles.navItem}>
              <Nav.Link eventKey="trending">Tendances</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="recent" className={styles.singleTabContent}>
              <div className={styles.recentPost}>
                <div className={styles.thumb}>
                  <Link to={process.env.PUBLIC_URL + "/blog"}>
                    <img
                      src={require("./../../../assets/images/blog/s1.jpg")}
                      alt="voopo voip"
                    />
                  </Link>
                </div>
                <div className={styles.content}>
                  <h4>
                    <Link to={process.env.PUBLIC_URL + "/blog"}>
                      FeedBack lance son nouveau site web.
                    </Link>
                  </h4>
                  <span>20 Sep 2021</span>
                </div>
              </div>
              <div className={styles.recentPost}>
                <div className={styles.thumb}>
                  <Link to={process.env.PUBLIC_URL + "/blog"}>
                    <img
                      src={require("./../../../assets/images/blog/s2.jpg")}
                      alt="voopo voip"
                    />
                  </Link>
                </div>
                <div className={styles.content}>
                  <h4>
                    <Link to={process.env.PUBLIC_URL + "/blog"}>
                      Tarifs et connectivité améliorés.
                    </Link>
                  </h4>
                  <span>30 Sep 2021</span>
                </div>
              </div>
              <div className={styles.recentPost}>
                <div className={styles.thumb}>
                  <Link to={process.env.PUBLIC_URL + "/blog"}>
                    <img
                      src={require("./../../../assets/images/blog/s3.jpg")}
                      alt="voopo voip"
                    />
                  </Link>
                </div>
                <div className={styles.content}>
                  <h4>
                    <Link to={process.env.PUBLIC_URL + "/blog"}>
                      FeedBack annonce un partenariat stratégique !
                    </Link>
                  </h4>
                  <span>15 Fév 2022</span>
                </div>
              </div>
              <div className={styles.recentPost}>
                <div className={styles.thumb}>
                  <Link to={process.env.PUBLIC_URL + "/blog"}>
                    <img
                      src={require("./../../../assets/images/blog/s4.jpg")}
                      alt="voopo voip"
                    />
                  </Link>
                </div>
                <div className={styles.content}>
                  <h4>
                    <Link to={process.env.PUBLIC_URL + "/blog"}>
                      Bilan annuel de la VoIP en 2021.
                    </Link>
                  </h4>
                  <span>01 Mar 2022</span>
                </div>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="trending" className={styles.singleTabContent}>
              <div className={styles.recentPost}>
                <div className={styles.thumb}>
                  <Link to={process.env.PUBLIC_URL + "/blog"}>
                    <img
                      src={require("./../../../assets/images/blog/s3.jpg")}
                      alt="voopo voip"
                    />
                  </Link>
                </div>
                <div className={styles.content}>
                  <h4>
                    <Link to={process.env.PUBLIC_URL + "/blog"}>
                      FeedBack annonce un partenariat stratégique !
                    </Link>
                  </h4>
                  <span>15 Fév 2022</span>
                </div>
              </div>
              <div className={styles.recentPost}>
                <div className={styles.thumb}>
                  <Link to={process.env.PUBLIC_URL + "/blog"}>
                    <img
                      src={require("./../../../assets/images/blog/s4.jpg")}
                      alt="voopo voip"
                    />
                  </Link>
                </div>
                <div className={styles.content}>
                  <h4>
                    <Link to={process.env.PUBLIC_URL + "/blog"}>
                      Bilan annuel de la VoIP en 2021.
                    </Link>
                  </h4>
                  <span>01 Mar 2022</span>
                </div>
              </div>
              <div className={styles.recentPost}>
                <div className={styles.thumb}>
                  <Link to={process.env.PUBLIC_URL + "/blog"}>
                    <img
                      src={require("./../../../assets/images/blog/s1.jpg")}
                      alt="voopo voip"
                    />
                  </Link>
                </div>
                <div className={styles.content}>
                  <h4>
                    <Link to={process.env.PUBLIC_URL + "/blog"}>
                      FeedBack lance son nouveau site web.
                    </Link>
                  </h4>
                  <span>20 Sep 2021</span>
                </div>
              </div>
              <div className={styles.recentPost}>
                <div className={styles.thumb}>
                  <Link to={process.env.PUBLIC_URL + "/blog"}>
                    <img
                      src={require("./../../../assets/images/blog/s2.jpg")}
                      alt="voopo voip"
                    />
                  </Link>
                </div>
                <div className={styles.content}>
                  <h4>
                    <Link to={process.env.PUBLIC_URL + "/blog"}>
                      Tarifs et connectivité améliorés.
                    </Link>
                  </h4>
                  <span>30 Sep 2021</span>
                </div>
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
        {/* <div className="voopo__nav nav justify-content-center" role="tablist">
          <a
            className="nav-item nav-link active"
            data-toggle="tab"
            href="#recent"
            role="tab"
          >
            Recent
          </a>
          <a
            className="nav-item nav-link"
            data-toggle="tab"
            href="#trending"
            role="tab"
          >
            Trending
          </a>
        </div>
        <div className="voopo__tab__container">
          
        </div> */}
      </div>
      {/* End Recent Post */}
      {/* Start Tag */}
      <div className={`${styles.vpWidget} ${styles.voopoTag}`}>
        <ul className={styles.tagList}>
          <li>
            <Link to={process.env.PUBLIC_URL + "/blog"}>Réseau</Link>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + "/blog"}>Communication</Link>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + "/blog"}>VoIP</Link>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + "/blog"}>FeedBack</Link>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + "/blog"}>Techno</Link>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + "/blog"}>Télécom</Link>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + "/blog"}>Entreprise</Link>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + "/blog"}>Cloud</Link>
          </li>
        </ul>
      </div>
      {/* End Tag */}
    </div>
  );
};

export default BlogSidebar;
