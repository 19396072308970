import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { animateScroll } from "react-scroll";
import { IoLogoTwitter, IoLogoVimeo, IoLogoPinterest } from "react-icons/io";
import { MdExpandLess } from "react-icons/md";
import { FaHeart } from "react-icons/fa";
import styles from "./Footer.module.scss";
import logo from "../../../assets/images/logo/voopo.png";
import logoDark from "../../../assets/images/logo/v2.png";

const Footer = ({ footerBg }) => {
  const [scroll, setScroll] = useState(0);
  const [top, setTop] = useState(0);

  useEffect(() => {
    setTop(100);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    animateScroll.scrollToTop();
  };

  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  return (
    <footer
      className={`footer-area ${footerBg === "white" ? styles.footer2 : ""}`}
    >
      <div
        className={`${styles.footerTop} ${
          footerBg === "white" ? "bg--cart-7" : "bg--cart-2"
        }`}
      >
        <div className="container">
          <div className="row">
            {/* Début du Widget Unique */}
            <div className="col-12 col-sm-6 col-lg-3">
              <div className={styles.singleWidget}>
                <div className={styles.logo}>
                  <Link to={process.env.PUBLIC_URL + "/"}>
                    <img
                      src={footerBg === "white" ? logoDark : logo}
                      alt="voip"
                    />
                  </Link>
                </div>
                <div className={styles.content}>
                  <p>
                    Innover la communication, réinventer la voix.
                  </p>
				  <br></br><img src="/arcep.png"></img><br></br><br></br><img src="/frenchteam.png"></img><font color="#01092c">--------</font><img src="/apnf.png"></img>
                </div>
              </div>
            </div>
            {/* Fin du Widget Unique */}
            {/* Début du Widget Unique */}
            <div className="col-12 col-sm-6 col-lg-2 offset-lg-1 xs__mt--40">
              <div className={styles.singleWidget}>
                <h2 className={styles.ftTitle}>Ligne d'Assistance</h2>
                <ul className={styles.ftContactLink}>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "#/"}>
                      Service de Téléphonie Cloud
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "#/"}>
                      Programme Entreprise
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "#/"}>
                      Guide de Service
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* Fin du Widget Unique */}
            {/* Début du Widget Unique */}
            <div className="col-lg-2 offset-lg-1 col-md-6 col-sm-6 col-12 sm__mt--40 md__mt--40">
              <div className={styles.singleWidget}>
                <h2 className={styles.ftTitle}>Infos</h2>
                <ul className={styles.ftContactLink}>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/service"}>
                      Pourquoi FeedBack
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "#/"}>
                      Tarification FeedBack
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "#/"}>
                      Fonctionnalités VoIP
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/about"}>
                      À Propos de Nous
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* Fin du Widget Unique */}
            {/* Début du Widget Unique */}
            <div className="col-lg-2 offset-lg-1 col-md-6 col-sm-6 col-12 sm__mt--40 md__mt--40">
              <div className={styles.singleWidget}>
                <h2 className={styles.ftTitle}>Ressources</h2>
                <ul className={styles.ftContactLink}>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "#/"}>
                      Support FeedBack
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "#/"}>
                      Blogs
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* Fin du Widget Unique */}
          </div>
        </div>
      </div>
      <div
        className={`${styles.copyright}  ${
          footerBg === "white" ? "bg--cart-8" : "bg--cart-3"
        }`}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-sm-6">
              <div className={styles.copyrightInner}>
                <p className="copyright-text">
                  &copy; FEEDBACK TELECOM {new Date().getFullYear()}
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className={styles.ftSocialLink}>
                <ul className={styles.socialIcon}>
                  <li>

                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        className={`scroll-top ${scroll > top ? "show" : ""}`}
        onClick={() => scrollToTop()}
      >
        <MdExpandLess />
      </button>
    </footer>
  );
};

Footer.propTypes = {
  footerBg: PropTypes.string
};

export default Footer;
