import React from "react";
import LocationMap from "../../../components/contact/contact-map";
import styles from "./ContactMap.module.scss";

const ContactMap = () => {
  return (
    <div className={`${styles.voopoAddress} bg--cart-10`}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-7 col-lg-5">
            <div className={styles.vpContactAddress}>
              <h2>Informations de Contact</h2>
              <div className="vp__address__container">
                {/* Start Single Address */}
                <div className={styles.vpAddress}>
                  <h4>Adresse Postale</h4>
                  <p>5 Rue des Fossillons, 93170 Bagnolet</p>
				  <p>Île-de-France, FRANCE</p>
                </div>
                {/* End Single Address */}
                {/* Start Single Address */}
                <div className={styles.vpAddress}>
                  <h4>Adresse E-Mail</h4>
                  <p>
                    <a href="mailto:info@feedback-voip.com">info@feedback-voip.com</a>
                  </p>
                </div>
                {/* End Single Address */}
                {/* Start Single Address */}
                <div className={styles.vpAddress}>
                  <h4>Téléphone</h4>
                  <p>
                    <a href="tel:+33184759302">+33 1 84 75 93 02</a>
                  </p>
                </div>
                {/* End Single Address */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactMap;
