import React from "react";
import { Link } from "react-router-dom";
import BlogSidebar from "../blog-sidebar";
import styles from "./BlogPostWithSidebar.module.scss";

const BlogPostWithSidebar = ({ sidebarPosition }) => {
  return (
    <div className="voopo__blog__area pb--120">
      <div className="container">
        <div className="row">
          <div
            className={` col-12 col-lg-8 ${
              sidebarPosition && sidebarPosition === "left"
                ? "order-1 order-lg-2"
                : ""
            }`}
          >
            <div className={styles.blogListWrapper}>
              {/* Début du Blog Unique */}
              <div className={styles.blogList}>
                <div className={styles.content}>
                  <h2>
                    <Link to={process.env.PUBLIC_URL + "/blog"}>
                      Qu'est-ce que la VoIP ?
                    </Link>
                  </h2>
                  <p>
                    La VoIP, ou Voix sur IP, représente une avancée majeure dans les communications modernes. Contrairement aux méthodes traditionnelles de communication téléphonique, la VoIP utilise Internet pour transmettre la voix, offrant ainsi des avantages significatifs tels qu'une qualité sonore améliorée, une accessibilité accrue à moindre coût, une flexibilité dans les fonctionnalités, et une portabilité sans précédent. En combinant ces éléments, la VoIP se positionne comme un moyen de communication plus efficace, économique et adaptable aux besoins changeants des utilisateurs contemporains.
                  </p>
                  <ul className={styles.blogMeta}>
                    <li>10 oct. 2021</li>
                    <li className={styles.separator}>/</li>
                    <li>
                      Par :{" "}
                      <Link to={process.env.PUBLIC_URL + "/blog"}>
                        Antonella Orlandi
                      </Link>
                    </li>
                  </ul>
                  <ul className={styles.blogCat}>
                    <li>
                      <Link to={process.env.PUBLIC_URL + "/blog"}>Actualités</Link>
                    </li>
                    <li>
                      <Link to={process.env.PUBLIC_URL + "/blog"}>Technologie</Link>
                    </li>
                    <li>
                      <Link to={process.env.PUBLIC_URL + "/blog"}>Réseau</Link>
                    </li>
                  </ul>
                </div>
              </div>
              {/* Fin du Blog Unique */}
            </div>
          </div>
          <div
            className={`col-12 col-lg-4 sm__mt--40 md__mt--40 ${
              sidebarPosition && sidebarPosition === "left"
                ? "order-2 order-lg-1"
                : ""
            } ${
              sidebarPosition && sidebarPosition === "left"
                ? styles.spaceRight
                : styles.spaceLeft
            }`}
          >
            <BlogSidebar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPostWithSidebar;
